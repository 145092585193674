import React from "react"
import { StaticQuery, Link, graphql } from "gatsby"

function first(data) {
  console.log(JSON.stringify(data.allMarkdownRemark.edges[0]))
  return data.allMarkdownRemark.edges[0];
}

function filtered(data) {
  //console.log(JSON.stringify(data))
  return data.allMarkdownRemark.edges
}

export default () => (
    <StaticQuery
      query={graphql`
        query BlogQuery {
          allMarkdownRemark(
           
            sort: { fields: frontmatter___date, order: DESC },
            filter: {frontmatter: {title: {ne: "EMPTY?"}}}
          ) {
            edges {
              node {
                frontmatter {
                  slug
                  title
                  template
                  date                 
                }
                id
                excerpt
              }
            }
          }
        }
      `}
      render={data => {
        return (
        <div>
          <h4>{filtered(data).length} Blog Posts</h4>
          {/* <div style={{border:"1px solid red"}}>
            <p>{first(data).node.frontmatter.title}</p>
          </div> */}



          {filtered(data).map(({ node }) => (
            <div key={node.id} className="regularSession eventBorder">
              <Link to={node.frontmatter.slug}>
                {/* <div className="d-block d-sm-none">
                  <div className="row" style={{ padding: "12px 20px" }}>
                    <h5>{node.frontmatter.title}</h5>
                    <p>
                      <i className="fas fa-calendar-alt"></i>{" "}
                      {node.frontmatter.date}
                    </p>
                    <p>{node.excerpt}</p>
                  </div>
                </div> */}
                <div className="d-none d-sm-block">
                  <div className="row">
                    <div className="two columns">
                      {/* <LogoO fileName="logo-o-480x480.jpg" /> */}
                      {/*TODO: image for listing of posts
                       {getImage(node)} */}
                      <p>
                        <i className="fas fa-calendar-alt"></i>{" "}
                        {node.frontmatter.date}
                      </p>
                    </div>
                    <div className="ten columns">
                      <h4 style={{ paddingTop: "25px" }}>
                        {node.frontmatter.title}
                      </h4>
                      <p>{node.excerpt}</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      )}}
    />
  )

