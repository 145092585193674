import React from "react";

import Layout from "../components/layout";
import Blog from "../components/blog";

export default ({ data }) => {
  return (
    <Layout>
      <div className="container">
        {/* <div className="css-debug">This is the blog page</div> */}
        <Blog></Blog>
      </div>
    </Layout>
  );
};